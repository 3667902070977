<template>
  <div>
    <b-card class="mb-2">
      <h2>Modifications des informations d'un agent d'établisssement</h2>
    </b-card>
    <div v-if="isCheckingAgentInfos">
      <p>Vérification en cours...</p>
      <p>
        <b-spinner
          style="width: 3rem; height: 3rem;"
          class="mx-auto d-block"
        />
      </p>
    </div>

    <b-card v-if="!isCheckingAgentInfos && !canViewAgentInfos">
      <h1>
        Informations de ce compte agent indisponible
      </h1>
    </b-card>

    <b-card
      v-if="canViewAgentInfos"
      class="mb-0"
    >
      <b-form
        class="auth-register-form mt-2"
        @submit.prevent="submitForm"
      >
        <!-- nom -->
        <b-form-group
          label="Nom"
          label-for="nom"
        >
          <b-form-input
            id="firstname"
            v-model="canViewAgentInfos.firstname"
            :state="$v.canViewAgentInfos.firstname.$error ? false : null"
            name="firstname"
          />
          <div v-if="$v.canViewAgentInfos.firstname.$error">
            <small
              v-if="$v.canViewAgentInfos.firstname.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>

        <!-- prenom -->
        <b-form-group
          label="Prénom"
          label-for="prenom"
        >
          <b-form-input
            id="lastname"
            v-model="canViewAgentInfos.lastname"
            :state="$v.canViewAgentInfos.lastname.$error ? false : null"
            name="lastname"
          />
          <div v-if="$v.canViewAgentInfos.lastname.$error">
            <small
              v-if="$v.canViewAgentInfos.lastname.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-form-group>

        <!-- can validate stats mensuel fiches --> 
        <b-form-group
          label="Autorisation Fiche statistiques mensuelles"
          label-for="validateStatsFiches"
        >
          <b-form-checkbox
            v-model="canViewAgentInfos.canValidateFicheStatMensuel"
            value=true
            class="custom-control-primary"
            name="validateStatsFiches"
          >
            Peut valider les fiches statistiques mensuelles
          </b-form-checkbox>
        </b-form-group>

        <!-- email -->
        <!-- <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="canViewAgentInfos.email"
            :state="$v.canViewAgentInfos.email.$error ? false : null"
            name="email"
          />
          <div v-if="$v.canViewAgentInfos.email.$error">
            <small
              v-if="$v.canViewAgentInfos.email.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
            <small
              v-if="$v.canViewAgentInfos.email.email.$invalid"
              class="text-danger"
            >Adresse email invalid</small>
          </div>
        </b-form-group> -->

        <div class="mb-1" v-if="false" >
          <!-- Styled -->
          <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
          <small
            v-if="$v.recaptchaResponse.isValid.$invalid"
            class="text-danger"
          >Veuillez résoudre le recaptcha pour continuer</small>
        </div>

        <!-- submit button -->
        <b-button
          variant="primary"
          block
          type="submit"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          Mettre à jour
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
// import localstorageService from '@/services/localstorage/localstorage.service'
import utilsService from '@/services/utils/utils.service'

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true })  };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
  },
  data() {
    return {
      canViewAgentInfos: {
        firstname: '',
        lastname: '',
        canValidateFicheStatMensuel: null,
        // email: '',
        // password: '',
        // confirmPassword: '',
        // etablissement: null,
      },
      isCheckingAgentInfos: false,
      // canViewAgentInfos: null,
      // currentUserId: localstorageService.getUserId(),

      recaptchaResponse: null,
      // passwordFieldType: 'password',
      isLoading: false,
      listEtablissements: [],
    }
  },
  computed: {
    etablissementDetails() {
      return this.canViewAgentInfos && this.canViewAgentInfos.etablissement
    },
    agentId() {
      return this.canViewAgentInfos && this.canViewAgentInfos._id
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isCheckingAgentInfos = true
        const { agentId } = val.params
        // this.isLoadingTableData = true
        this.getAgent(agentId)
          .then(res => {
            this.canViewAgentInfos = res.data.resource
            res.data.formsCreate.map(item => {
              this.forms = this.forms.concat(item.data)
            })
            this.isCheckingAgentInfos = false
            // this.isLoadingTableData = false
          })
          .catch(err => {
            this.isCheckingAgentInfos = false

            console.log(err)
          })
      },
    },
  },

  validations() {
    return {
      canViewAgentInfos: {
        firstname: { required },
        lastname: { required },
        // email: { required, email },
        // password: { required },
        // etablissement: { required },
        // confirmPassword: {
        //   isValid: val => val && val === this.form.password,
        // },
      },

      recaptchaResponse: { isValid: val => val && val.type === 'success' },
      isLoading: false,
    }
  },
  async mounted() {
    await this.action_findEtablissement()
      .then(response => {
        console.log('users-list', response)
        this.listEtablissements = response.data.inscriptions
      })
      .catch(err => {
        this.isLoadingTableData = false

        console.log('error-users-list', err)
      })
  },
  methods: {
    ...mapActions('etablissements', { action_findEtablissement: 'findEtablissement' }),
    ...mapActions('etablissements', {
      updateAgent: 'updateAgent',
    }),
    ...mapActions('etablissements', {
      getEtablissement: 'getEtablissement',
      getAgent: 'getAgent',
    }),
    // togglePasswordInputType() {
    //   eslint-disable-next-line no-unused-expressions
    //   this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    // },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },

    async submitForm() {
      // console.log('vdfvndjhvbdf');
      if (this.isLoading) return
      const isFormCorrect = await this.$v.$validate()
      // if (!isFormCorrect) return
      this.isLoading = true
      // console.log('ton');

      const {
        isAdminEtablissement, isAgentEtablissement, currentUserId,
      } = utilsService.currentUserUtils()

      if (!isAdminEtablissement) {
        console.log("Vous n'êtes pas autorisé à faire cette action")
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: "Vous n'êtes pas autorisé à faire cette action",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      const etablissementId = currentUserId
      let updatedata = {
        firstname: this.canViewAgentInfos.firstname,
        lastname: this.canViewAgentInfos.lastname,

        // email: this.canViewAgentInfos.email,
        // password: this.form.password,
        // etablissementId,
      }

      this.canViewAgentInfos.canValidateFicheStatMensuel ? updatedata.canValidateFicheStatMensuel = true : updatedata.canValidateFicheStatMensuel = false
      
      console.log('********************** :', this.canViewAgentInfos, updatedata)

      this.updateAgent({ id: this.canViewAgentInfos._id, data: updatedata })
        .then(result => {
          // console.log(' &&&&&&&&&& result:  &&&&&&', result)
          this.isLoading = false
          this.$router.push({ name: 'etablissements-agents-list' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opération réussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
